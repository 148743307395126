import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>South Main Group</h1>
        {/*<img src={logo} className="App-logo" alt="logo" />*/}
        <p className="App-detail">
          Proprietors of fine digital platforms for the modern era.
        </p>
          <p className="App-small">Excellence, simplicity, and no nonsense.</p>

      </header>
      <footer className="App-small">
        <p>©2020 South Main Group, LLC</p>
          <p>Made with 🥵 in TN</p>
      </footer>
    </div>
  );
}

export default App;
